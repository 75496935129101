import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useCompanyDashboardStore } from '../store/company-dashboard.store'
import { AppTableBody } from '../../table/component/app-analog/atom/AppTableBody'
import { AppTableBodyRow } from '../../table/component/app-analog/atom/AppTableBodyRow'
import { AppTableRowCell } from '../../table/component/app-analog/atom/AppTableRowCell'
import { Chip, Tooltip } from '@mui/material'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { UserCourseSummary } from './UserCourseSummary'
import { UsersLevel } from '../model/company-dashboard'

export const UserTable = observer(() => {
  const companyDashboardStore = useCompanyDashboardStore()
  const [selectedUser, setSelectedUser] = useState<string>()

  const closeSettings = async () => {
    setSelectedUser(undefined)
    companyDashboardStore.userCourseSummary = undefined
  }

  return (
    <AppTableBody>
      {companyDashboardStore.usersLevel && companyDashboardStore.usersLevel.map((user: UsersLevel) => (
        <AppTableBodyRow hover key={user.userId} sx={styles.row}>
          <AppTableRowCell>{user.userName}</AppTableRowCell>
          <AppTableRowCell>{user.email}</AppTableRowCell>
          <AppTableRowCell>{user.profession}</AppTableRowCell>
          <AppTableRowCell>{new Date(user.dateOfJoining).toLocaleDateString()}</AppTableRowCell>
          <AppTableRowCell>
            {user.organisationLevels.map(level => (
              <Chip key={level} label={level} color='primary' sx={{ marginLeft: '3px' }} />
            ))}
          </AppTableRowCell>
          <AppTableRowCell>
            <Tooltip title='Visa Mer'>
              <AppIconButton onClick={() => setSelectedUser(user.userId)}>
                <RemoveRedEyeIcon />
              </AppIconButton>
            </Tooltip>
          </AppTableRowCell>
        </AppTableBodyRow>
      ))}
      {selectedUser && (
        <ModalWindow modalProps={{
          open: true,
          maxWidth: 'lg',
          onClose: () => closeSettings(),
          PaperProps: {
            sx: {
              maxWidth: '60%',
            },
          },
        }}>
          <UserCourseSummary userId={selectedUser} />
        </ModalWindow>
      )}
    </AppTableBody>
  )
})

const styles = {
  row: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}