import { observer } from 'mobx-react-lite'
import ReactPlayer from 'react-player'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Image } from 'src/feature/image/component/Image'

export type MediaDisplayProps = {
  url: string
  type: string
  compact?: boolean
}

export const MediaDisplay = observer(({ url, type, compact }: MediaDisplayProps) => {
  if (type.startsWith('image')) {
    return (<Image src={url} alt='Image' outerProps={{ sx: styles.imgOuter, style: compact ? { maxHeight: 200 } : { maxHeight: 300 } }} innerProps={{ style: { objectFit: compact ? 'cover' : 'contain', height: 'auto' } }} />)
  }

  if (type.startsWith('video')) {
    return (<AppBox sx={styles.video}><ReactPlayer url={url} controls={!compact} width={'100%'} height={compact ? '200px' : '300px'} /></AppBox>)
  }

  return (
    <>
      {type}
    </>
  )
})

const styles = {
  imgOuter: {
    alignSelf: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  video: {
    alignSelf: 'center',
    maxWidth: '100%',
  },
}