import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FileContent } from 'use-file-picker/dist/interfaces'
import { useUserStore } from '../../user/store/user.store'
import { AppAvatar } from '../../../component/common/analog/molecule/AppAvatar'
import { AppBox } from '../../../component/common/analog/molecule/AppBox'
import { useLoader } from '../../../hook/bool/useLoader'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppDivider } from 'src/component/common/analog/molecule/AppDivider'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import { useSkillAssessmentStore } from '../store/skill-assessment.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Word } from 'src/feature/localization/component/atom/Word'
import { SkillAssessmentRating } from './SkillAssessmentRating'
import { SkillSet } from '../model/skill-assessment-model'
import { SkillAssessmentLevels } from './SkillAssessmentLevels'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useNavigate } from 'react-router-dom'
import { Palette } from 'src/feature/theme/model/Palette'
import { Box } from '@mui/material'
  
export const SkillAssessmentMenu = observer(() => {
  const [tempAvatarDataURL] = useState<FileContent | undefined>()
  const [avatarUrl] = useState<string | undefined>()
  const [skillassessmentSet, setSkillAssessmentSet] = useState<SkillSet>({ id: '', categoryId: '', setName: '', setHint: '', selectedSetLevel: 0, levels: [] })
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { avatar, userData } = useUserStore()
  const skillAssessmentStore = useSkillAssessmentStore()
  const navigate = useNavigate()
  const loader = useLoader()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')
  const showTempAvatar = (tempAvatarDataURL || avatarUrl)
  const displayedAvatar = showTempAvatar ? tempAvatarDataURL?.content ? tempAvatarDataURL?.content : avatarUrl : avatar

  useEffect(() => {
    const profession: string = userData?.profession || 'UNKNOWN'
    profession !== 'UNKNOWN' && skillAssessmentStore.skillSets?.categoryDescription === undefined && skillAssessmentStore.getSkillSets(profession)
  }, [])

  const handleRateSkillSetClick = (skillSet: SkillSet) => {
    setSkillAssessmentSet(skillSet)
    setIsModalOpen(true)
  }

  return (
    <>
      <AppBox sx={{ ...styles.buttonContainer, display: !userData?.profession && 'none' }}>
        <AppButton variant='contained' color="primary" onClick={() => navigate(appRoutePath.learning)}>Done</AppButton>
      </AppBox>
      <AppBox sx={styles.mainContainer}>
        <AppBox sx={{ ...styles.profileContainer, width: !userData?.profession || isLoading ? '100%' : { xs: '100%', md: '100%', lg: '30%' } }}>
          <AppAvatar src={displayedAvatar} outerProps={{ squareSize: 130, withOpacity: loader.isShowing }} showVerfiedMark={true} isVerified={userData?.verified} />
          <AppBox sx={{ alignItems: 'center' }}>Welcome<Word sx={styles.wordContainer}>{`${userData?.firstName} ${userData?.lastName}`}</Word></AppBox>
          {userData?.profession && <>
            <Title2 sx={{ margin: 0 }}>{userData?.profession}</Title2>
            <AppDivider />
            <AppProgressLine isShowing={isLoading || skillAssessmentStore.skillSets?.categoryDescription === undefined} />
            {!isLoading && skillAssessmentStore.skillSets?.categoryDescription && <Paragraph sx={{ wordBreak: 'break-all' }}>{skillAssessmentStore.skillSets?.categoryDescription}</Paragraph>}
          </>}
          {!userData?.profession && <>
            <Box sx={styles.wordContainer}>För att starta kompetensanalysen behöver du fylla i din yrkesroll.</Box>
            <Box sx={styles.wordContainer}><Word sx={styles.linkContainer} onClick={() => navigate(appRoutePath.editProfile)}>Klicka här</Word> för att redigera.</Box>
          </>}
        </AppBox>
        <AppBox>
          <AppProgressLine isShowing={userData?.profession && (isLoading || skillAssessmentStore.skillSets?.skills === undefined)} />
          {userData?.profession && !isLoading && skillAssessmentStore.skillSets?.skills && <SkillAssessmentRating onRateSkillClick={handleRateSkillSetClick} />}
        </AppBox>
      </AppBox>
      <SkillAssessmentLevels skillSet={skillassessmentSet} isModalOpen={isModalOpen} isModalClose={() => setIsModalOpen(false)} />
    </>
  )
})

const styles = {
  mainContainer: {
    flexDirection: { xs: 'column', md: 'column', lg: 'row' },
    gap: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 7,
  },
  wordContainer: {
    marginLeft: '5px',
    fontWeight: 600,
    fontSize: '18px',
  },
  buttonContainer: {
    marginBottom: '1em',
    width: '100%',
    maxHeight: '40px',
    justifyContent: 'flex-end',
  },
  linkContainer: {
    color: Palette.HAN_BLUE,
    cursor: 'pointer',
  },
}
