import { TableRowProps } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../../../../../component/common/analog/molecule/AppBox'

export type AppTableRowProps = NotOverridableComponentProps<TableRowProps>

export function AppTableRow(props: AppTableRowProps) {
  return (
    <AppBox
      component={TableRow}
      display={'table-row'}
      {...props}
    />
  )
}
