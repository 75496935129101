import { NotOverridableComponentProps } from '../../../../../model/Component'
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer/TableContainer'
import { handlePassedSx } from '../../../../theme/model/Sx'
import { AppPaper } from '../../../../../component/common/analog/molecule/AppPaper'

export type AppTableContainerProps = NotOverridableComponentProps<TableContainerProps>

export function AppTableContainer(props: AppTableContainerProps) {
  const { sx = [], ...rest } = props

  return (
    <AppPaper
      component={TableContainer}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = {
  maxWidth: 'unset',
}
