import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { handlePassedSx } from '../../../theme/model/Sx'
import { Theme } from '@mui/material'
import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { NotOverridableComponentProps } from '../../../../model/Component'

export type AppAnalogBarProps = NotOverridableComponentProps<MuiAppBarProps>

export function AppAnalogBar(props: AppAnalogBarProps) {
  const { position = 'sticky', enableColorOnDark = true, sx = [], ...rest } = props

  return (
    <AppBox
      component={MuiAppBar}
      enableColorOnDark={enableColorOnDark}
      position={position}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = ({ handleCssUnit, palette }: Theme) => ({
  boxShadow: 'none',
  backdropFilter: `blur(${handleCssUnit(20)}) !important`,
  backgroundColor: 'unset',
  border: 0,
  borderBottom: `${handleCssUnit(1)} solid ${palette.grey[0]}`,
  py: { xs: 10, md: 10 },
  borderRadius: '0px',
})
