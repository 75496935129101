import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CoursePlanItemProgress } from '../../model/ELearningCoursePlanItem'
import { Palette } from 'src/feature/theme/model/Palette'
import { CircularProgressWithLabel } from './CircularProgressWithLabel'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import { Tooltip } from '@mui/material'

export type CourseProgressRowProp = {
  progress?: CoursePlanItemProgress
}

export const CourseProgressRow = observer(({ progress }: CourseProgressRowProp) => {
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning

  return (
    <AppBox sx={styles.row}>
      <CircularProgressWithLabel value={progress?.percentage || 0} sx={styles.progress} />
      <Tooltip title={(progress?.testPassed ? texts.testPassed() : texts.testNotPassed())}><MilitaryTechIcon sx={{ fontSize: '40px', color: (progress?.testPassed ? Palette.HAN_BLUE : Palette.BRIGHT_GREY_2) }} /></Tooltip>
    </AppBox>
  )
})

const styles = {
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progress: {
    width: '50px',
    height: '50px',
  },
}
