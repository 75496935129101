import { handlePassedSx } from '../../../../../feature/theme/model/Sx'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../AppBox'
import { SxHelperStyle } from '../../../../../feature/theme/style/sx-helper.style'

export type AppStackItemProps = NotOverridableComponentProps

export function AppStackItem(props: AppStackItemProps) {
  const { sx = [], ...rest } = props

  return (
    <AppBox
      component={'li' as React.ElementType}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = {
  ...SxHelperStyle.noIndents,
  listStyle: 'none',
  display: 'flex',
}
