import TableBody, { TableBodyProps } from '@mui/material/TableBody'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../../../../../component/common/analog/molecule/AppBox'

export type AppTableBodyProps = NotOverridableComponentProps<TableBodyProps>

export function AppTableBody(props: AppTableBodyProps) {
  return (
    <AppBox
      component={TableBody}
      display={'table-row-group'}
      {...props}
    />
  )
}
