import { Main } from '../template/Main'
import { NarrowContent } from '../../common/original/template/NarrowContent'
import { ChildrenProps } from '../../../model/React'
import { ConditionalWrapper } from '../../common/original/atom/ConditionalWrapper'

export interface SimpleLayoutProps extends ChildrenProps {
  isNarrowContainer?: boolean
}

export function SimpleLayout(props: SimpleLayoutProps) {
  const { isNarrowContainer = false } = props

  return (
    <Main sx={styles.root}>
      <ConditionalWrapper condition={isNarrowContainer} wrapper={children => <NarrowContent>{children}</NarrowContent>}>
        {props.children}
      </ConditionalWrapper>
    </Main>
  )
}

const styles = {
  root: {
    mb: {
      xs: 50,
      md: 92,
    },
  },
}
