import { axiosInstance } from 'src/util/axios/instance.axios'
import { SkillCategory } from '../model/skill-assessment-model'

export async function getSkillSets(profession: string): Promise<SkillCategory> {
  const { data } = await axiosInstance.request<SkillCategory>({
    url: `/skill-analysis/skill-sets/${profession}`,
  })

  return data
}

export async function addSkillAssessment(set_id: string, selected_set_level: number): Promise<any> {
  const { data } = await axiosInstance.request({
    url: '/skill-analysis/skill-assessment',
    method: 'post',
    data: {
      set_id,
      selected_set_level,
    },
  })

  return data
}
