import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { SxHelperStyle } from 'src/feature/theme/style/sx-helper.style'
import { SkillAssessmentMenu } from '../component/SkillAssessmentMenu'

export const SkillAnalysisPage = observer(() => {
  return (
    <AppBox sx={styles.mainContainer}>
      <SkillAssessmentMenu />
    </AppBox>
  )
})

const styles = {
  mainContainer: {
    ...SxHelperStyle.flexColumnCenterY,
  },
}
