import { GenericStore } from 'src/util/mobx/generic-store/generic.store'
import { getSkillSets } from '../api/skill-assessment-api'
import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { createStore } from 'src/util/mobx/generic-store/store-creator'
import { SkillCategory } from '../model/skill-assessment-model'

export class skillAssessmentStore extends GenericStore {
  skillSets: SkillCategory | undefined
  editSkillIndex: number | undefined

  constructor() {
    super('skillAssessmentStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  reset() {
    this.skillSets = undefined
    this.editSkillIndex = undefined
  }

  @GenericStoreAsyncMethod()
  async getSkillSets(profession: string) {
    const data: any = await getSkillSets(profession)
    this.skillSets = data
  }
}

export const {
  storeInstance: skillAssessmentStoreInstance,
  useStore: useSkillAssessmentStore,
} = createStore(new skillAssessmentStore())
