import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { PageTitle } from 'src/feature/localization/component/atom/PageTitle'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Image } from 'src/feature/image/component/Image'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FastForwardIcon from '@mui/icons-material/FastForward'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Palette } from 'src/feature/theme/model/Palette'
import GoodHabitz from 'src/feature/image/asset/goodhabitz.svg'
import Diploma from 'src/feature/image/asset/diploma.svg'
import Udemy from 'src/feature/image/asset/udemy.svg'
import { API_BASE_URL, UDEMY_ID_SUB_DOMAIN, DIPLOMA_ID, GOODHABITS_ID, UDEMY_ID } from 'src/util/constant'
import { CourseProgressRow } from './CourseProgressRow'
import { useUserStore } from 'src/feature/user/store/user.store'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import SettingsIcon from '@mui/icons-material/Settings'
import { OrgSettingsModal } from './OrgSettingsModal'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { Tooltip } from '@mui/material'
import { OrganisationRole } from 'src/feature/control-panel/model/OrganisationRole'

export type CoursePresentationProps = {
  courseId: string
}

export const CoursePresentation = observer(({ courseId }: CoursePresentationProps) => {
  const eLearningStore = useELearningStore()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const navigate = useNavigate()
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning
  const { isLoading } = eLearningStore.getGenericFieldsState('getCourseInfo')
  const [showOrgSettings, setShowOrgSettings] = useState(false)

  const toggleFavorite = async (e: React.FormEvent<HTMLFormElement>, id: string) => {
    e.stopPropagation()
    await eLearningStore.toggleFavoriteCourse(id)
    await eLearningStore.updatePersonalCoursePlan()
  }
  
  useEffect(() => {
    eLearningStore.getCourseInfo(courseId)
  }, [courseId])

  if (isLoading || !eLearningStore.course) {
    return <AppBox><AppProgressLine isShowing={true} /></AppBox>
  }

  const course = eLearningStore.course
  const startUrl = course.provider.id === GOODHABITS_ID ? 'https://my.goodhabitz.com/sso/yohrs'
    : course.provider.id === UDEMY_ID ? `https://${UDEMY_ID_SUB_DOMAIN}.udemy.com/organization/init-sso-login/?redirect_url=${course.url}`
      : `${API_BASE_URL}/e-learning/startdiploma/${course.id}?token=${authStore.accessToken}`

  return (
    <>
      <PageTitle textValue={course.title} />
      <AppPaper p={24} sx={{ borderRadius: '10px', alignItems: 'center' }}>
        <AppBox sx={styles.container}>
          <AppBox sx={styles.holders}>
            <Image src={course.imageUrl} alt={course.title} outerProps={{ sx: styles.imageRoot }} innerProps={{ sx: styles.image }} />
            {course.progress && <CourseProgressRow progress={course.progress} />}
          </AppBox>
          <AppBox sx={styles.holders} style={{ flexGrow: 2 }}>
            {!course.deleted && <AppBox sx={styles.buttonGroup}>
              {!userStore.hasSubscriptionPlan(course.provider.products) && !userStore.hasActiveTrial() && <AppButton variant='contained' onClick={() => navigate(appRoutePath.activateLearning)} textValue={texts.activateLearningButton} />}
              {!userStore.hasSubscriptionPlan(course.provider.products) && userStore.hasActiveTrial() && <Tooltip title={texts.daysLeftInTrial(userStore.daysLeftInTrial())}><a href={startUrl} target='blank' style={{ textDecoration: 'none' }}><AppButton onClick={() => eLearningStore.targetCourseUrl = course.url} textValue={course.progress ? texts.continueButton() : texts.startButton()} variant='contained' endIcon={course.progress ? <FastForwardIcon /> : <PlayArrowIcon />} /></a></Tooltip>}
              {userStore.hasSubscriptionPlan(course.provider.products) && <a href={startUrl} target='blank' style={{ textDecoration: 'none' }}><AppButton onClick={() => eLearningStore.targetCourseUrl = course.url} textValue={course.progress ? texts.continueButton() : texts.startButton()} variant='contained' endIcon={course.progress ? <FastForwardIcon /> : <PlayArrowIcon />} /></a>}
              <AppBox>
                {authStore.hasRole(OrganisationRole.LearningAdministrator) && <AppIconButton onClick={() => setShowOrgSettings(true)} sx={styles.iconButton}><SettingsIcon fontSize='medium' /></AppIconButton>}
                <AppIconButton onClick={(e: React.FormEvent<HTMLFormElement>) => toggleFavorite(e, course.id)} sx={styles.iconButton}>{course.isFavorite ? <FavoriteIcon fontSize='medium' /> : <FavoriteBorderIcon fontSize='medium' />}</AppIconButton>
              </AppBox>
            </AppBox>}
            {course.deleted && <Caption textValue={texts.deletedCourse} />}
            <div dangerouslySetInnerHTML={{ __html: course.description }} />
          </AppBox>
        </AppBox>
      </AppPaper>
      {course.provider.id === GOODHABITS_ID && <AppBox sx={styles.partner}>
        <Caption textValue={texts.partnerText} />
        <Image src={GoodHabitz} alt='GoodHabitz' />
      </AppBox>}
      {course.provider.id === DIPLOMA_ID && <AppBox sx={styles.partner}>
        <Caption textValue={texts.partnerText} />
        <Image src={Diploma} alt='Diploma' />
      </AppBox>}
      {course.provider.id === UDEMY_ID && <AppBox sx={styles.partner}>
        <Caption textValue={texts.partnerText} />
        <Image src={Udemy} alt='UDEMY' />
      </AppBox>}
      {authStore.hasRole(OrganisationRole.LearningAdministrator) && <OrgSettingsModal show={showOrgSettings} courseId={course.id} onClose={() => setShowOrgSettings(false)} />}
    </>
  )
})

const styles = {
  container: {
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    gap: 20,
    minWidth: '100%',
  },
  buttonGroup: {
    flexDirection: { xs: 'column-reverse', md: 'row-reverse', lg: 'row-reverse' },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconButton: {
    borderWidth: 0,
    color: Palette.HAN_BLUE,
    width: '60px',
    height: '60px',
  },
  holders: {
    flexDirection: 'column',
    gap: 20,
  },
  imageRoot: {
    overflow: 'hidden',
    alignSelf: 'center',
  },
  image: {
    height: { xs: '300px', md: '400px' },
    width: { xs: '300px', md: '400px' },
    objectFit: 'scale-down',
  },
  partner: {
    flexDirection: 'column',
    gap: 10,
    margin: 10,
    width: 'max(200px)',
  },
}
