import { Theme, useTheme } from '@mui/material'
import { Fade as HamburgerDiv } from 'hamburger-react'
import { SxProps } from '@mui/system'
import { AppUnstyledButton } from '../../../../../component/common/analog/molecule/button/AppUnstyledButton'
import { BoolProps } from '../../../../../model/Boolean'

interface Props extends BoolProps {
  sx?: SxProps<Theme>
}

export function Hamburger(props: Props) {
  const { isTrue, toggle, sx } = props
  const theme = useTheme()

  return (
    <AppUnstyledButton sx={sx}>
      <HamburgerDiv
        toggled={isTrue}
        color={theme?.palette?.text?.primary}
        toggle={toggle}
        direction={'left'}
      />
    </AppUnstyledButton>
  )
}
