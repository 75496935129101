import { observer } from 'mobx-react-lite'
import { DialogProps, Theme } from '@mui/material'
import { Title3 } from '../../../../feature/localization/component/atom/Title3'
import { AppBox, AppBoxProps } from '../molecule/AppBox'
import { AppUnstyledButton } from '../molecule/button/AppUnstyledButton'
import { IsTrue } from '../../../../model/Boolean'
import { TranslatableTextVariantProps } from '../../../../feature/localization/component/atom/TranslatableText'
import { AppDialogContent, AppDialogContentProps } from '../molecule/dialog/AppDialogContent'
import { ChildrenProps } from '../../../../model/React'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { AppDialog } from '../molecule/dialog/AppDialog'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'
import CloseIcon from '@mui/icons-material/Close'

export interface ModalProps extends ChildrenProps {
  modalProps: Omit<DialogProps, 'open'> & { open: IsTrue }
  disableCloseButton?: boolean
  titleProps?: TranslatableTextVariantProps
  dialogContentProps?: AppDialogContentProps
  topContent?: React.ReactNode
  topProps?: AppBoxProps
}

export const ModalWindow = observer((props: ModalProps) => {
  const {
    children,
    modalProps: {
      open,
      maxWidth = 'sm',
      transitionDuration = 200,
      scroll = 'paper',
      PaperProps: {
        sx: PaperSx = [],
        ...restPaperProps
      } = {},
      ...restRootProps
    } = {},
    topProps: {
      sx: topSx = [],
      mb: topMb = 24,
      ...restTopProps
    } = {},
    dialogContentProps: {
      sx: dialogContentSx = [],
      ...restDialogContentProps
    } = {},
    disableCloseButton,
  } = props

  return (
    <AppDialog
      TransitionComponent={props.modalProps.TransitionComponent}
      maxWidth={maxWidth}
      open={Boolean(open)}
      transitionDuration={transitionDuration}
      scroll={scroll}
      PaperProps={{
        sx: [
          styles.paper,
          ...handlePassedSx(PaperSx),
        ],
        ...restPaperProps,
      }}
      {...restRootProps}
    >
      <AppBox
        mb={topMb}
        sx={[
          styles.top,
          ...handlePassedSx(topSx),
        ]}
        {...restTopProps}
      >
        {props.titleProps ? (
          <Title3
            {...props.titleProps}
            my={0}
          />
        ) : props.topContent && (
          <>
            {props.topContent}
          </>
        )}

        {!disableCloseButton && (
          <AppUnstyledButton onClick={props.modalProps.onClose} sx={styles.closeButton} type={'button'}>
            <CloseIcon fontSize='large' sx={{ color: 'text.primary' }} />
          </AppUnstyledButton>
        )}

      </AppBox>

      <AppDialogContent
        sx={[
          styles.content,
          ...handlePassedSx(dialogContentSx),
        ]}
        {...restDialogContentProps}
      >
        {children}
      </AppDialogContent>
    </AppDialog>
  )
})

const styles = {
  paper: {
    paddingTop: {
      xs: 16,
      md: 25,
    },
    paddingBottom: {
      xs: 16,
      md: 25,
    },
    paddingLeft: {
      xs: 19,
      md: 30,
    },
    paddingRight: {
      xs: 19,
      md: 30,
    },
    width: 1,
    margin: {
      xs: '10px',
      md: '30px',
    },
  },
  top: {
    ...SxHelperStyle.flexCenterX,
    justifyContent: 'space-between',
    width: 1,
  },
  closeButton: ({ handleCssUnit }: Theme) => ({
    width: handleCssUnit(20),
    height: handleCssUnit(20),
    ml: 'auto',
    overflow: 'unset',
  }),
  content: {
    padding: 0,
    margin: 0,
    width: 1,
  },
}
