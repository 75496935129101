import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { AppCheckbox } from 'src/feature/form/component/analog/AppCheckbox'
import { AppDatePicker } from 'src/feature/form/component/analog/AppDatePicker'
import { AppFormControlWithLabel } from 'src/feature/form/component/analog/AppFormControlWithLabel'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import OrgLevelsSelector from './OrgLevelsSelector'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useB2bStore } from 'src/feature/b2b/store/b2b.store'
import { AppCircleLoader } from 'src/component/common/analog/molecule/loader/AppCircleLoader'

export type OrgSettingsModalProps = {
  show: boolean
  onClose: () => void
  courseId: string
}

export const OrgSettingsModal = observer((props: OrgSettingsModalProps) => {
  const { translation } = useLocalizationStore()
  const b2bStore = useB2bStore()
  const { orgSettings } = translation.pages.eLearning
  const [enabled, setEnabled] = useState(false)
  const [mandatory, setMandatory] = useState(false)
  const [deadline, setDeadline] = useState<Date | undefined>(new Date())
  const [anyLanguage, setAnyLanguage] = useState(true)
  const [includeSubLevels, setIncludeSubLevels] = useState(false)
  const [orgLevels, setOrgLevels] = useState<string[]>([])
  const saveState = b2bStore.getGenericFieldsState('saveCourseOrganisationPlanItem')
  const loadState = b2bStore.getGenericFieldsState('loadCourseOrganisationPlanItem')
  const loadOrgLevelState = b2bStore.getGenericFieldsState('handleLoadOrganisationLevels')

  useEffect(() => {
    b2bStore.handleLoadOrganisationLevels()
    b2bStore.loadCourseOrganisationPlanItem(props.courseId).then(() => {
      if (b2bStore.courseOrganisationSettings) {
        setEnabled(true)
        setMandatory(b2bStore.courseOrganisationSettings.mandatory)
        setDeadline(b2bStore.courseOrganisationSettings.deadline)
        setAnyLanguage(b2bStore.courseOrganisationSettings.allLanguages || false)
        setIncludeSubLevels(b2bStore.courseOrganisationSettings.includeInOrganisationSubLevels)
        setOrgLevels(b2bStore.courseOrganisationSettings.organisationLevels)
      }
    })
  }, [props.courseId])

  const savePlanItem = async () => {
    if (enabled) {
      await b2bStore.saveCourseOrganisationPlanItem({
        id: b2bStore.courseOrganisationSettings?.id || '',
        includeInOrganisationSubLevels: includeSubLevels,
        mandatory,
        deadline: mandatory ? deadline : undefined,
        organisationLevels: orgLevels.some(o => o === '') ? [] : orgLevels,
        allLanguages: anyLanguage,
      }, props.courseId)
    } else {
      await b2bStore.saveCourseOrganisationPlanItem(null, props.courseId)
    }

    props.onClose()
  }

  return (
    <ModalWindow
      modalProps={{
        open: props.show,
        onClose: props.onClose,
      }}
      topProps={{
        mb: 28,
      }}
      titleProps={{
        textValue: orgSettings.title,
      }}
    >
      {loadState.isLoading || loadOrgLevelState.isLoading ? <AppCircleLoader isShowing={true} />
        : <AppBox sx={styles.container}>
          <Paragraph textValue={orgSettings.description} />
          <AppFormControlWithLabel control={<AppCheckbox size='large' checked={enabled} id='enabled' onClick={() => setEnabled(!enabled)} />} label={orgSettings.activeCheckbox} />
          <AppFormControlWithLabel control={<AppCheckbox size='large' disabled={!enabled} checked={mandatory} id='mandatory' onClick={() => setMandatory(!mandatory)} />} label={orgSettings.mandatoryCheckbox} />
          <AppDatePicker label={orgSettings.deadlineLabel()} id='deadline' disabled={!enabled || !mandatory} key="start-date" defaultValue={deadline} onChange={setDeadline} />
          <AppFormControlWithLabel control={<AppCheckbox size='large' disabled={!enabled} checked={anyLanguage} id='anyLanguage' onClick={() => setAnyLanguage(!anyLanguage)} />} label={orgSettings.allowAllLanguages} />
          <OrgLevelsSelector label={orgSettings.targetAudience()} placeholder='' defaultValues={orgLevels.length === 0 ? [''] : orgLevels} onChange={setOrgLevels} disabled={!enabled} organiationLevels={b2bStore.organisationLevels} showOptionForAll={true} />
          <AppFormControlWithLabel control={<AppCheckbox size='large' disabled={!enabled} checked={includeSubLevels} id='anyLanguage' onClick={() => setIncludeSubLevels(!includeSubLevels)} />} label={orgSettings.inheritSubLevels} />
          {saveState.isLoading && <AppCircleLoader isShowing={true} />}
          {!saveState.isLoading && <AppButton onClick={savePlanItem} textValue={translation.buttons.save} variant={'contained'} m={2} />}
        </AppBox>}
    </ModalWindow>
  )
})

const styles = {
  container: {
    flexDirection: 'column',
  },
}