import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from 'src/util/mobx/generic-store/generic.store'
import { createStore } from 'src/util/mobx/generic-store/store-creator'
import { getCoursesAssignedOverview, getCoursesOverview, getUsers, getUserCourseSummary, getOrganisationLevels } from '../api/company-dashboard'
import { CoursesAssignedOverview, CoursesOverview, UserCourseSummary, UsersLevel } from '../model/company-dashboard'
import { OrganisationLevel } from 'src/feature/b2b/model/OrganisationLevel'

export class companyDashboardStore extends GenericStore {
  coursesOverview: CoursesOverview | undefined
  coursesAssignedOverview: CoursesAssignedOverview | undefined
  usersLevel: UsersLevel[] | undefined
  userCourseSummary: UserCourseSummary[] | undefined
  organisationLevels: OrganisationLevel[] | undefined
  selectedLevel = 'all'

  constructor() {
    super('companyDashboardStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }
  
  reset() {
    this.coursesOverview = undefined
    this.coursesAssignedOverview = undefined
    this.usersLevel = undefined
    this.userCourseSummary = undefined
    this.organisationLevels = undefined
  }

  @GenericStoreAsyncMethod()
  async getCoursesOverview() {
    const data: CoursesOverview = await getCoursesOverview()
    this.coursesOverview = data
  }

  @GenericStoreAsyncMethod()
  async getCoursesAssignedOverview(level: string) {
    const data: CoursesAssignedOverview = await getCoursesAssignedOverview(level)
    this.coursesAssignedOverview = data
  }

  @GenericStoreAsyncMethod()
  async getOrganisationLevels() {
    const data: OrganisationLevel[] = await getOrganisationLevels()
    this.organisationLevels = data
  }

  @GenericStoreAsyncMethod()
  async getUsers(level: string) {
    const data: UsersLevel[] = await getUsers(level)
    this.usersLevel = data
  }

  @GenericStoreAsyncMethod()
  async getUserCourseSummary(userId: string) {
    const data: UserCourseSummary[] = await getUserCourseSummary(userId)
    this.userCourseSummary = data
  }
}

export const {
  storeInstance: companyDashboardStoreInstance,
  useStore: useCompanyDashboardStore,
} = createStore(new companyDashboardStore())
