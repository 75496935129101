import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { delCoursePlanItem, getCategories, getCourse, getCourseSubCategory, getCoursesByCategory, getCoursesBySearch, getELearningCourseUrl, getELearningUrl, getPersonalCoursePlan, getRecommendedCourses, getSubCategories, putFavoriteCourse } from '../api/e-learning'
import { mainStoreInstance } from '../../../store/main.store'
import { Maybe } from '../../../model/Util'
import { isUrl, URI } from '../../../model/Link'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { AppLogger } from '../../../util/logger'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { ELearningCourseCategory } from '../model/ELearningCourseCategory'
import { ELearningCoursePlanItem } from '../model/ELearningCoursePlanItem'
import { CourseItem } from '../model/ELearningCourse'
import { CourseListItem } from '../model/ELearningCourseListItem'

export const eLearningAccessDenied = 'Access is denied' as const

export class ELearningStore extends GenericStore {
  eLearningUri!: Maybe<URI> | typeof eLearningAccessDenied
  expireDate: Date | undefined
  categories: ELearningCourseCategory[] | undefined
  personalCourcePlan: ELearningCoursePlanItem[] | undefined
  courseList: CourseListItem[] | undefined
  courseCount: number | undefined
  course: CourseItem | undefined
  recommendedCourses: CourseListItem[] | undefined
  targetCourseUrl: string | undefined
  searchList: CourseListItem[] | undefined
  searchController: AbortController | undefined
  categoriesWithSubs: any | undefined
  subCategories: ELearningCourseCategory[] | undefined
  selectedFilters: ELearningCourseCategory[] | undefined
  courseSubCategory: ELearningCourseCategory[] | undefined

  constructor() {
    super('ELearningStore')

    super.observe(this)
    super.persist({ encrypt: false, excludedProperties: ['courseList', 'course', 'recommendedCourses', 'searchList', 'searchController'] })
  }

  get isAccessDenied() {
    return this.eLearningUri === eLearningAccessDenied
  }

  get isELearningUriValid() {
    return isUrl(this.eLearningUri) && this.expireDate && this.expireDate > (new Date())
  }

  reset() {
    this.eLearningUri = null
    this.categories = undefined
    this.personalCourcePlan = undefined
    this.expireDate = undefined
    this.courseList = undefined
    this.courseCount = undefined
    this.course = undefined
    this.recommendedCourses = undefined
    this.targetCourseUrl = undefined
    this.searchList = undefined
    this.categoriesWithSubs = undefined
    this.subCategories = undefined
    this.selectedFilters = undefined
    this.courseSubCategory = undefined
  }

  clearSearch() {
    this.searchList = undefined
  }

  @GenericStoreAsyncMethod()
  async search(term: string, skip: number, take: number, lang: string) {
    if (this.searchController) {
      this.searchController.abort()
    }

    this.searchController = new AbortController()
    const data = await getCoursesBySearch(term, skip, take, this.searchController, this.selectedFilters || [], lang)
    this.searchList = data.items
    this.searchController = undefined
    this.subCategories = data.subCategories
  }

  @GenericStoreAsyncMethod()
  async appendSearch(term: string, skip: number, take: number, lang: string) {
    if (this.searchController) {
      this.searchController.abort()
    }

    this.searchController = new AbortController()
    const data = await getCoursesBySearch(term, skip, take, this.searchController, this.selectedFilters || [], lang)
    this.searchList = [...this.searchList || [], ...data.items]
    this.searchController = undefined
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleGetELearningUri() {
    this.eLearningUri = await getELearningUrl()
    this.expireDate = new Date(new Date().getTime() + (3 * 60 * 60000))
  }

  @GenericStoreAsyncMethod()
  async loadCategoriesIfNeeded() {
    if (!this.categories) {
      this.categories = await getCategories()
    }
  }

  @GenericStoreAsyncMethod()
  async loadSubCategories() {
    this.categoriesWithSubs = await getSubCategories()
  }

  @GenericStoreAsyncMethod()
  async updatePersonalCoursePlan() {
    this.personalCourcePlan = await getPersonalCoursePlan()
  }

  async removeCoursePlanItem(id: string) {
    this.personalCourcePlan = this.personalCourcePlan?.filter(c => c.id !== id)
    await delCoursePlanItem(id)
  }

  @GenericStoreAsyncMethod()
  async getCoursesByCategory(category: string, skip: number, take: number, lang: string) {
    const data = await getCoursesByCategory(category, skip, take, lang)
    this.courseCount = data.count
    this.courseList = data.items
  }

  @GenericStoreAsyncMethod()
  async appendCoursesByCategory(category: string, skip: number, take: number, lang: string) {
    const data = await getCoursesByCategory(category, skip, take, lang)
    this.courseList = [...this.courseList || [], ...data.items]
  }

  @GenericStoreAsyncMethod()
  async getCourseSubCategory(categoryKey: string) {
    this.courseSubCategory = await getCourseSubCategory(categoryKey)
  }

  @GenericStoreAsyncMethod()
  async getCourseInfo(courseId: string) {
    this.course = await getCourse(courseId)
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async getELearningCourseUri(courseId: string): Promise<string> {
    return await getELearningCourseUrl(courseId)
  }

  @GenericStoreAsyncMethod()
  async toggleFavoriteCourse(courseId: string) {
    const course = this.courseList?.find(c => c.id === courseId)

    if (course) {
      course.isFavorite = !course.isFavorite
    }
    
    if (this.course && this.course.id === courseId) {
      this.course.isFavorite = !this.course.isFavorite
    }

    await putFavoriteCourse(courseId)
  }

  @GenericStoreAsyncMethod()
  async getRecommendedCourses(lang: string) {
    this.recommendedCourses = await getRecommendedCourses(lang)
  }

  async handleGotoELearning() {
    try {
      mainStoreInstance.startLoading()
      await this.handleGetELearningUri()

      if (this.isELearningUriValid && this.eLearningUri) {
        window.open(this.eLearningUri, '_blank', 'noopener,noreferrer')
      } else {
        throw new Error('Cannot goto E-learning')
      }
    } catch (e) {
      AppLogger.error(e)
      throw e
    } finally {
      mainStoreInstance.endLoading()
    }
  }
}

export const {
  storeInstance: eLearningStoreInstance,
  useStore: useELearningStore,
  StoreProvider: ELearningStoreProvider,
} = createStore(new ELearningStore())
