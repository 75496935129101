import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import { useSkillAssessmentStore } from '../store/skill-assessment.store'
import { addSkillAssessment } from '../api/skill-assessment-api'
import { Palette } from 'src/feature/theme/model/Palette'
import { SkillSet, SkillSetLevel } from '../model/skill-assessment-model'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { useState } from 'react'
import { AppConfetti } from 'src/component/common/analog/molecule/confetti/AppConfetti'

export type SkillAssessmentLevelsProps = {
  isModalClose: () => void
  isModalOpen: boolean
  skillSet: SkillSet
}

export const SkillAssessmentLevels = observer((props: SkillAssessmentLevelsProps) => {
  const skillAssessmentStore = useSkillAssessmentStore()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')
  const [isAllSkillsRated, setIsAllSkillsRated] = useState(false)

  const addUserSkillAssessment = async (set_id: string, selected_set_level: number) => {
    await addSkillAssessment(set_id, selected_set_level)
    props.skillSet.selectedSetLevel = selected_set_level
  }
  
  const handleCloseModal = () => {
    props.isModalClose()
    checkIfAllSkillsAreRated()
  }

  const checkIfAllSkillsAreRated = () => {
    const allSkillsRated: boolean = skillAssessmentStore.skillSets?.skills?.every(skill => skill.selectedSetLevel !== null) || false
    setIsAllSkillsRated(allSkillsRated)
  }

  return (
    <>
      <AppConfetti show={isAllSkillsRated} />
      <ModalWindow
        modalProps={{
          open: props.isModalOpen,
          onClose: () => handleCloseModal(),
          PaperProps: {
            sx: {
              maxWidth: '50%',
              borderRadius: '15px',
            },
          },
        }}
      >
        <AppBox sx={styles.mainContainer}>
          {!isLoading && props.skillSet.levels?.length !== 0 && (<>
            <Title1 m={0}>{props.skillSet?.setName}:</Title1>
            <Paragraph sx={{ fontWeight: 400 }}>{props.skillSet?.setHint}</Paragraph>
            <AppBox sx={styles.skillsContainer}>
              {props.skillSet?.levels.map((skill: SkillSetLevel) => (
                <AppPaper
                  sx={{
                    ...styles.descriptionContainer,
                    backgroundColor: skill?.setLevel === props.skillSet?.selectedSetLevel ? Palette.HAN_BLUE : '',
                  }}
                  key={skill?.id}
                  onClick={() => addUserSkillAssessment(props.skillSet?.id, skill?.setLevel)}
                >
                  <Paragraph>{skill?.setLevelDescription}</Paragraph>
                </AppPaper>
              ))}
            </AppBox>
          </>)}
        </AppBox>
      </ModalWindow>
    </>
  )
})

const styles = {
  mainContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  skillsContainer: {
    flexDirection: 'column',
    gap: 10,
    width: '100%',
  },
  descriptionContainer: {
    borderColor: Palette.HAN_BLUE,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#36bbaf96',
    },
    borderRadius: '15px',
    padding: '0.7em',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
}
