import { useEffect } from 'react'
import { AppTableBody } from 'src/feature/table/component/app-analog/atom/AppTableBody'
import { useCompanyDashboardStore } from '../store/company-dashboard.store'
import { AppTableBodyRow } from 'src/feature/table/component/app-analog/atom/AppTableBodyRow'
import { AppTableRowCell } from 'src/feature/table/component/app-analog/atom/AppTableRowCell'
import { CustomTable } from 'src/feature/table/component/template/CustomTable'
import { CustomTableHead } from 'src/feature/table/component/template/CustomTableHead'
import { ExtendedColumn } from 'src/feature/table/model/Table'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import { CourseProgressWithLabel } from './CourseProgressWithLabel'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import SchoolIcon from '@mui/icons-material/School'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import { Typography } from '@mui/material'

export type UserCourseSummaryProps = {
  userId: string;
};

export const UserCourseSummary = observer((props: UserCourseSummaryProps) => {
  const companyDashboardStore = useCompanyDashboardStore()
  
  useEffect(() => {
    companyDashboardStore.getUserCourseSummary(props.userId)
  }, [companyDashboardStore, props.userId])

  const columns = ['Utbildning', 'Tilldelad', 'Obligatorisk', 'Avklarad Utbildning', 'Progress', 'Avklarad']

  return (
    <AppBox sx={styles.mainContainer}>
      {companyDashboardStore.userCourseSummary && (
        <AppBox sx={styles.overviewContainer}>
          {[
            {
              label: 'Antal Utbildningar',
              value: companyDashboardStore.userCourseSummary.length,
            },
            {
              label: 'Genomförda Utbildningar',
              value: Math.round((companyDashboardStore.userCourseSummary.reduce((sum, userCourse) => sum + userCourse.progress.percentage, 0) / (companyDashboardStore.userCourseSummary.length * 100)) * 100),
              isProgress: true,
            },
            {
              label: 'Godkända Utbildningar',
              value: Math.round((companyDashboardStore.userCourseSummary.filter(userCourse => userCourse.progress.testPassed).length / companyDashboardStore.userCourseSummary.length) * 100),
              isProgress: true,
            },
          ].map(item => (
            <AppBox key={item.label} sx={styles.cardContainer}>
              {item.isProgress ? (
                <CourseProgressWithLabel value={item.value} progressSize={58} />
              ) : (
                <Typography sx={styles.metricContainer}>{item.value}</Typography>
              )}
              <Title3 m={0}>{item.label}</Title3>
            </AppBox>
          ))}
        </AppBox>
      )}
      <CustomTable>
        <CustomTableHead columns={columns.map((value, index) => ({ key: index.toString(), value } as ExtendedColumn))} />
        <AppTableBody>
          {companyDashboardStore.userCourseSummary && companyDashboardStore.userCourseSummary.map(course => (
            <AppTableBodyRow hover key={course.courseId} sx={styles.row}>
              <AppTableRowCell>{course.title}</AppTableRowCell>
              <AppTableRowCell>
                {!course.isUserSelected ? (
                  <InventoryOutlinedIcon color='primary' />
                ) : (
                  <InventoryOutlinedIcon color='disabled' />
                )}
              </AppTableRowCell>
              <AppTableRowCell>
                {course.mandatory ? (
                  <VerifiedUserIcon color='primary' />
                ) : (
                  <VerifiedUserIcon color='disabled' />
                )}
              </AppTableRowCell>
              <AppTableRowCell>
                {course.progress.testPassed ? (
                  <CheckCircleOutlinedIcon color='primary' />
                ) : (
                  <CheckCircleOutlinedIcon color='disabled' />
                )}
              </AppTableRowCell>
              <AppTableRowCell>
                <CourseProgressWithLabel value={course.progress.percentage} progressSize={50} />
              </AppTableRowCell>
              <AppTableRowCell>
                {course.progress.completed ? (
                  <SchoolIcon color='primary' />
                ) : (
                  <SchoolIcon color='disabled' />
                )}
              </AppTableRowCell>
            </AppTableBodyRow>
          ))}
        </AppTableBody>
      </CustomTable>
    </AppBox>
  )
})

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  overviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5rem',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
  },
  row: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  longCell: {
    maxWidth: 200,
  },
  metricContainer: {
    fontSize: '42px',
    fontWeight: '500',
  },
}
