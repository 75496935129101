import { TableCell, TableCellProps } from '@mui/material'
import { handlePassedSx } from '../../../../theme/model/Sx'
import { TranslatableBox, TranslatableBoxProps } from '../../../../localization/component/atom/TranslatableBox'
import { NotOverridableComponentProps } from '../../../../../model/Component'

export type AppTableCellProps = NotOverridableComponentProps<TableCellProps & TranslatableBoxProps>

export function AppTableCell(props: AppTableCellProps) {
  const { sx = {}, ...rest } = props

  return (
    <TranslatableBox
      component={TableCell}
      display={'table-cell'}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = {
  border: 0,
  padding: '0.944em 1.388em',
  textAlign: 'left',
  whiteSpace: 'nowrap',
}
