import { SocialIcons } from './SocialIcons'
import { Logo, LogoType } from '../../../component/common/original/organism/Logo'
import { AskQuestionButton } from '../../question/component/AskQuestionButton'
import { AppBox } from '../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { Theme } from '@mui/material'
import { AppFooter } from '../../../component/common/analog/molecule/basic/AppFooter'
import { SxHelperStyle } from '../../theme/style/sx-helper.style'
import { AppContainer } from '../../../component/common/analog/molecule/AppContainer'

export const Footer = observer(() => {
  return (
    <AppFooter sx={styles.root}>
      <AskQuestionButton />

      <AppContainer>
        
        <AppBox sx={styles.bottomContent}>
          <Logo type={LogoType.WHITE} />
          <SocialIcons />
        </AppBox>

      </AppContainer>
    </AppFooter>
  )
})

const styles = {
  root: ({ palette, spacing }: Theme) => ({
    backgroundColor: palette.common.black,
    position: 'relative',
    color: palette.common.white,
    padding: {
      xs: spacing(15, 0),
      md: spacing(20, 0),
    },
  }),
  topContent: {
    li: {
      ...SxHelperStyle.flexColumn,
      alignItems: 'flex-start',
    },
  },
  section: SxHelperStyle.flexColumn,
  bottomContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  columnLinks: ({ palette }: Theme) => ({
    margin: '1.111em 0 0',
    '& li': {
      mt: '0.555em',
    },
    '& li a': {
      color: palette.common.white,
    },
  }),
  divider: ({ spacing }: Theme) => ({
    margin: {
      xs: spacing(50, 0, 20),
      md: spacing(80, 0, 50),
    },
  }),
}
