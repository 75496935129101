import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { companyDashboardIds } from '../router/model/RouteId'
import { CompanyDashboardSidebar } from './component/CompanyDashboardSidebar'

export const CompanyDashboardRoutes = [
  withLayout(
    {
      layout: <RouterLayout sidebar={<CompanyDashboardSidebar />} />,
      routes: getRouteObjectList(companyDashboardIds),
    },
  ),
]
