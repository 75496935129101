import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Collapse, Theme } from '@mui/material'
import { useELearningStore } from '../../store/e-learning.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Word } from 'src/feature/localization/component/atom/Word'
import { startCase } from 'lodash'
import { AnimatedIconButton } from 'src/component/common/analog/molecule/button/AnimatedIconButton'
import { ArrowDownward } from '@mui/icons-material'
import { keyframes } from '@emotion/react'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import { Palette } from 'src/feature/theme/model/Palette'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'

export type CourseListProps = {
  categoryKey: string
  categoryTitle: string
}

export const CourseList = observer((props: CourseListProps) => {
  const eLearningStore = useELearningStore()
  const { translation, locale } = useLocalizationStore()
  const navigate = useNavigate()
  const texts = translation.pages.eLearning
  const { isLoading } = eLearningStore.getGenericFieldsState('getCoursesByCategory')
  const pageSize = 50
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const categoryColor = categories[props.categoryKey as ObjectKey]
  const [showCourses, setShowCourses] = useState(false)
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | ''>('')

  useEffect(() => {
    eLearningStore.getCourseSubCategory(props.categoryKey)
  }, [])

  const getCourses = async (subCategory: ELearningCourseCategory) => {
    setShowCourses(true)
    setSelectedSubCategory(subCategory.id)
    await eLearningStore.getCoursesByCategory(subCategory.id, 0, pageSize, locale)
  }

  const handleBackButton = () => {
    setSelectedSubCategory('')
    eLearningStore.courseList = []
    eLearningStore.courseCount = undefined
    showCourses ? setShowCourses(false) : navigate(appRoutePath.learning)
  }

  const extendResults = async () => {
    if (eLearningStore.courseList && eLearningStore.courseList.length !== eLearningStore.courseCount) {
      await eLearningStore.appendCoursesByCategory(selectedSubCategory, eLearningStore.courseList.length, pageSize, locale)
    }
  }

  return (
    <>
      <AppBox sx={styles.iconContainer} onClick={handleBackButton}>
        <AnimatedIconButton
          className="icon"
          sx={styles.iconButton}
          color='primary'
        >
          <ArrowBackIcon />
        </AnimatedIconButton>
        <Word textValue={showCourses ? texts.backToSubcategories : texts.backToCategories} sx={styles.wordWrapper} />
      </AppBox>
      <Title1 textValue={props.categoryTitle} />
      <AppPaper p={24}>
        <AppBox sx={styles.root}>
          <AppProgressLine isShowing={isLoading} />
          {!isLoading && eLearningStore.courseSubCategory && eLearningStore.courseSubCategory.length === 0 && <Caption textValue={texts.missingCourse} />}
          <Collapse in={!showCourses}>
            <AppBox sx={styles.subCategoryTilesWrapper}>
              {!isLoading && eLearningStore.courseSubCategory && eLearningStore.courseSubCategory.length !== 0 && eLearningStore.courseSubCategory
                .map(subCategory => (
                  <AppBox key={subCategory.id} sx={styles.subCategoryTilesContainer} style={{ backgroundColor: categoryColor.color() }} onClick={() => getCourses(subCategory)}>
                    <Title3 style={{ color: Palette.WHITE }}>{startCase(subCategory.titleLocale)}</Title3>
                  </AppBox>
                ))
              }
            </AppBox>
          </Collapse>
          <Collapse in={showCourses}>
            <AppBox sx={styles.courseContainer}>
              {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length === 0 && <Caption textValue={texts.missingCourse} />}
              {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && (
                <AppBox sx={styles.subCategoriesList}>
                  {eLearningStore.courseList.map(course => (
                    <CourseListPresentationItem key={course.id} item={course} />
                  ))}
                </AppBox>
              )}
              {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && eLearningStore.courseList.length !== eLearningStore.courseCount && (
                <AppBox sx={styles.viewMoreContainer} onClick={extendResults}>
                  <Word textValue='View more' sx={styles.wordWrapper} />
                  <AnimatedIconButton
                    className="icon"
                    sx={styles.iconButton}
                    color='primary'
                  >
                    <ArrowDownward />
                  </AnimatedIconButton>
                </AppBox>
              )}
            </AppBox>
          </Collapse>
        </AppBox>
      </AppPaper>
    </>
  )
})

const moveDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
`

const moveRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5px);
  }
`

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(10),
    flexDirection: 'column',
    width: '100%',
    maxHeight: '100%',
    borderRadius: '5px',
  }),
  subCategoryTilesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  subCategoryTilesContainer: {
    padding: 20,
    display: 'flex',
    borderRadius: '10px',
    minHeight: '110px',
    minWidth: '200px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    PointerEvent: 'none',
  },
  courseContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
  },
  subCategoriesList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  iconButton: {
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
    padding: '0px',
    fontSize: '15px',
  },
  wordWrapper: {
    fontSize: '15px',
    color: '#36BBAF',
    fontWeight: 500,
  },
  viewMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#36BBAF',
    },
    '&:hover .icon': {
      animation: `${moveDown} 0.3s forwards`,
    },
  },
  iconContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#36BBAF',
    },
    '&:hover .icon': {
      animation: `${moveRight} 0.3s forwards`,
    },
  },
}
