import { AppStack } from '../../../../../component/common/analog/molecule/list/AppStack'
import { Hamburger } from '../molecule/Hamburger'
import { Theme } from '@mui/material'
import { AppNav } from '../../../../../component/common/analog/molecule/basic/AppNav'
import { BaseAppHeaderBar } from './BaseAppHeaderBar'
import { AppSwipeableDrawer } from '../../../../../component/common/analog/template/AppSwipeableDrawer'
import { BoolProps } from '../../../../../model/Boolean'
import { SxHelperStyle } from '../../../../theme/style/sx-helper.style'
import { ChildrenProps } from '../../../../../model/React'

export type SwipeableMobileNavDrawerProps = BoolProps & ChildrenProps

export function SwipeableMobileNavDrawer(props: SwipeableMobileNavDrawerProps) {
  return (
    <AppSwipeableDrawer
      anchor={'right'}
      open={props.isTrue}
      onClose={props.makeFalse}
      onOpen={props.makeTrue}
      PaperProps={{
        sx: styles.paper,
      }}
    >
      <BaseAppHeaderBar>
        <Hamburger sx={styles.hamburger} {...props} />
      </BaseAppHeaderBar>

      <AppNav sx={styles.nav} isContainer={true}>
        <AppStack sx={styles.list} direction={'column'} spacing={42} alignItems={'center'}>
          {props.children}
        </AppStack>
      </AppNav>
    </AppSwipeableDrawer>
  )
}

const styles = {
  paper: ({ palette }: Theme) => ({
    width: 1,
    backgroundColor: palette.background.default,
    borderRadius: '0px',
  }),
  container: {
    width: 1,
    display: 'flex',
  },
  hamburger: {
    ml: 'auto',
  },
  nav: {
    height: 1,
    ...SxHelperStyle.flexCenter,
  },
  list: {
    '& li': {
      ...SxHelperStyle.flexCenterY,
    },
  },
}
