import { observer } from 'mobx-react-lite'
import { Box, Theme } from '@mui/material'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import { themeStoreInstance } from '../../store/theme.store'
import { AppThemeMode } from '../../model/Mode'
import { AnimatedIconButton } from 'src/component/common/analog/molecule/button/AnimatedIconButton'

export const ThemeButton = observer(() => {
  const handleModeChange = (newMode: AppThemeMode) => {
    themeStoreInstance.changeMode(newMode)
  }

  const iconButtonStyle = {
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }

  return (
    <Box sx={styles.root}>
      <AnimatedIconButton
        onClick={() => handleModeChange('dark')}
        sx={{
          ...(themeStoreInstance.mode === 'dark' ? { color: '#FFFACD' } : {}),
          ...iconButtonStyle,
        }}
        color='inherit'
      >
        <DarkModeIcon />
      </AnimatedIconButton>
      <AnimatedIconButton
        onClick={() => handleModeChange('light')}
        sx={{
          ...(themeStoreInstance.mode === 'light' ? { color: '#FFA500' } : {}),
          ...iconButtonStyle,
        }}
        color='inherit'
      >
        <LightModeIcon />
      </AnimatedIconButton>
    </Box>
  )
})

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    p: 3,
  }),
}
