import { TableRowProps } from '@mui/material'
import { AppTableRow } from './AppTableRow'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../../../../../component/common/analog/molecule/AppBox'

export type AppTableBodyRowProps = NotOverridableComponentProps<TableRowProps>

export function AppTableBodyRow(props: AppTableBodyRowProps) {
  return (
    <AppBox
      component={AppTableRow}
      {...props}
    />
  )
}
