import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { CategoryItem } from './CategoryItem'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'

export const CategoryList = observer(() => {
  const eLearningStore = useELearningStore()
  
  useEffect(() => {
    eLearningStore.loadCategoriesIfNeeded()
  }, [])

  const transform = (category: ELearningCourseCategory, index: number) => {
    return <CategoryItem key={category.id} item={category} index={index} />
  }

  return (
    <AppCarousel showProgressBar={false} showDots={true}>
      {eLearningStore.categories && eLearningStore.categories.map(transform)}
    </AppCarousel>
  )
})
