import { TranslatableText } from '../../../localization/component/atom/TranslatableText'
import { ExtendedColumn } from '../../model/Table'
import { AppStack } from '../../../../component/common/analog/molecule/list/AppStack'
import { AppStackItem } from '../../../../component/common/analog/molecule/list/AppStackItem'

interface Props {
  subColumns: ExtendedColumn[]
}

export function TableHeadSubColumns(props: Props) {
  return (
    <AppStack sx={styles.root}>
      {props.subColumns.map((subColumn: ExtendedColumn) => (
        <AppStackItem key={subColumn.key}>
          <TranslatableText
            textValue={subColumn.value}
            sx={styles.item}
          />
        </AppStackItem>
      ))}
    </AppStack>
  )
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  item: () => ({
    fontSize: '0.82em',
    padding: '0 0.4em',
  }),
}
