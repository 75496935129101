import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from '../../../../localization/store/localization.store'
import { useLocation } from 'react-router-dom'
import { appRoutePath } from '../../../../router/util/app-route-path'
import { AppInternalLink } from '../../../../../component/common/analog/molecule/link/AppInternalLink'
import { useBreakpoints } from '../../../../theme/hook/useBreakpoints'
import { MobileHeader } from '../template/MobileHeader'
import { DesktopHeader } from '../template/DesktopHeader'

export const PublicHeader = observer(() => {
  const { translation } = useLocalizationStore()
  const { pathname } = useLocation()
  const { isMd } = useBreakpoints()

  const authButtonText = translation.util.signUp

  return (
    <>
      {isMd ? (
        <MobileHeader pathname={pathname}>
          <AppInternalLink
            sx={styles.singleLineButton}
            kind={'linkLikeButton'}
            textValue={translation.util.signIn}
            variant="outlined"
            to={appRoutePath.signIn}
          />
          <AppInternalLink
            sx={styles.singleLineButton}
            kind={'linkLikeButton'}
            textValue={authButtonText}
            variant="contained"
            to={appRoutePath.signUp}
          />
        </MobileHeader>
      ) : (
        <DesktopHeader>
          <AppInternalLink
            sx={styles.singleLineButton}
            kind={'linkLikeButton'}
            textValue={translation.util.signIn}
            variant="outlined"
            to={appRoutePath.signIn}
          />
          <AppInternalLink
            sx={styles.singleLineButton}
            kind={'linkLikeButton'}
            textValue={authButtonText}
            variant="contained"
            to={appRoutePath.signUp}
          />
        </DesktopHeader>
      )}
    </>
  )
})

const styles = {
  singleLineButton: {
    minWidth: 'auto',
    whiteSpace: 'nowrap',
  },
}