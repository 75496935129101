import { observer } from 'mobx-react-lite'
import { UserTable } from '../component/UserTable'
import { CustomTable } from 'src/feature/table/component/template/CustomTable'
import { CustomTableHead } from 'src/feature/table/component/template/CustomTableHead'
import { ExtendedColumn } from 'src/feature/table/model/Table'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useCompanyDashboardStore } from '../store/company-dashboard.store'
import { useEffect } from 'react'
import { MenuItem, Select } from '@mui/material'
import { OrganisationLevel } from 'src/feature/b2b/model/OrganisationLevel'
import { AppInputLabel } from 'src/feature/form/component/analog/AppInputLabel'

export const CompanyUsersPage = observer(() => {
  const companyDashboardStore = useCompanyDashboardStore()
  const columns = ['Namn', 'E-post', 'Roll', 'Skapad', 'Avdelning', '']

  useEffect(() => {
    companyDashboardStore.organisationLevels === undefined && companyDashboardStore.getOrganisationLevels()
    companyDashboardStore.getUsers(companyDashboardStore.selectedLevel)
  }, [companyDashboardStore])

  const level = async (level: string) => {
    companyDashboardStore.selectedLevel = level
    companyDashboardStore.usersLevel = undefined
    companyDashboardStore.getUsers(companyDashboardStore.selectedLevel)
  }

  return (
    <AppBox sx={styles.mainContainer}>
      <AppBox sx={styles.filterContainer}>
        <AppInputLabel id='filter_by_levels' textValue='Filtrera Efter Avdelning' sx={{ fontSize: '1.1em', fontWeight: 100 }} />
        <Select
          labelId='filter_by_levels'
          sx={styles.dropdown}
          value={companyDashboardStore.selectedLevel || 'all'}
          onChange={event => level(event.target.value)}
        >
          <MenuItem key='all' value='all' defaultValue='all'>All</MenuItem>
          {companyDashboardStore.organisationLevels && companyDashboardStore.organisationLevels.map((level: OrganisationLevel) => (
            <MenuItem key={level.id} value={level.key}>{level.name}</MenuItem>
          ))}
        </Select>
      </AppBox>
      <CustomTable>
        <CustomTableHead columns={Object.entries(columns).map(([key, value]) => ({ key, value } as ExtendedColumn))} />
        <UserTable />
      </CustomTable>
    </AppBox>
  )
})

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.5em',
  },
  dropdown: {
    width: '10rem',
    height: '2rem',
  },
}
