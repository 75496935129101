import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const scrollToOptions = {
  top: 0,
  left: 0,
  behavior: 'smooth',
} as const

export function useScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(scrollToOptions)
  }, [pathname])
}
