import React, { FC, Fragment, useCallback, useEffect } from 'react'
import { autorun } from 'mobx'
import { useSnackbar } from 'notistack'
import { observer } from 'mobx-react-lite'
import { NotifierCloseButton } from './NotifierCloseButton'
import { INote } from '../model/notification'
import { useNotificationStore } from '../store/notification.store'

const Notifier: FC = observer((): JSX.Element | null => {
  const { enqueueSnackbar } = useSnackbar()
  const notificationStore = useNotificationStore()
  const { notifications, removeSnackbar } = notificationStore
  let displayed: number[] = []

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id]
  }

  const _enqueueSnackbar = useCallback((notification: INote) => {
    enqueueSnackbar(notification.message, {
      ...notification.options,
      action: (
        <Fragment>
          <NotifierCloseButton notificationKey={notification.options.key}/>
        </Fragment>
      ),
    })
  }, [])

  useEffect(() => {
    autorun(() => {
      notifications.forEach((notification: INote) => {
        // Do nothing if snackbar is already displayed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (displayed.includes(notification.key)) {
          return
        }

        _enqueueSnackbar(notification)
        // Keep track of snackbars that we've displayed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        storeDisplayed(notification.key)
        // Dispatch action to remove snackbar from mobx store
        removeSnackbar(notification)
      })
    })
  }, [])

  return null
})

export { Notifier }
