import { observer } from 'mobx-react-lite'
import { Theme } from '@mui/material'
import { AppBox } from '../AppBox'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { EmblaCarouselType } from 'embla-carousel-react'
import { useState, useEffect, useCallback } from 'react'

export const CarouselButtons = observer(({ emblaApi }: { emblaApi?: EmblaCarouselType }) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  )
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return (
    <AppBox sx={styles.root}>
      <IconButton sx={styles.button} onClick={scrollPrev} disabled={prevBtnDisabled} color='inherit'>
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton sx={styles.button} onClick={scrollNext} disabled={nextBtnDisabled} color='inherit'>
        <ArrowForwardIosIcon />
      </IconButton>
    </AppBox>
  )
})

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    pointerEvents: 'none',
    padding: '10px 0px',
  }),
  button: (theme: Theme) => ({
    backgroundColor: theme.palette.background.grey,
    opacity: 0.5,
    pointerEvents: 'auto',
    borderRadius: '0px',
    height: '100%',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      opacity: 0.3,
      pointerEvents: 'auto',
    },
  }),
}
