import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { userStoreInstance } from '../../user/store/user.store'
import { SignInFormModel } from '../model/SignIn'
import { BaseInfoFormModel } from '../../on-boarding/model/BaseInfo'
import {
  forgotPassword,
  resetPassword,
  signIn,
  signInByToken,
  signOut,
  signUp,
  signUpByGoogle,
  ssoVerify,
  updateAccessToken,
  verifyAccount,
} from '../api/auth'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { ResetPasswordFormModel } from '../model/ResetPassword'
import { localizationStoreInstance } from '../../localization/store/localization.store'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { ForgotPasswordFormModel } from '../model/ForgotPassword'
import { localTranslate } from '../../localization/model/Translate'
import { axiosInstance } from '../../../util/axios/instance.axios'
import { SamlResponse } from '../page/SsoVerifyPage'
import { OrganisationRole } from 'src/feature/control-panel/model/OrganisationRole'

export class AuthStore extends GenericStore {
  isOpenAppViaAuth!: boolean | null
  accessToken!: string | null
  refreshToken!: string | null

  constructor() {
    super('AuthStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false })
  }

  get isAuthorized() {
    return Boolean(this.accessToken)
  }

  reset() {
    this.isOpenAppViaAuth = null
    this.accessToken = null
    this.refreshToken = null
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleOpenApp() {
    // if (!this.isOpenAppViaAuth) {
    await Promise.allSettled([
      userStoreInstance.handleGetUser(),
      userStoreInstance.handleSetAvatar(),
    ])
    // }

    this.isOpenAppViaAuth = false
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleSignIn(values: SignInFormModel) {
    const response = await signIn(values)
    userStoreInstance.userData = { ...response.userData, organisation: this.getJwtField('org') }
    userStoreInstance.sitemap = response.sitemap
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleSignInByToken(token: string) {
    const response = await signInByToken(token)
    userStoreInstance.userData = { ...response.userData, organisation: this.getJwtField('org') }
    userStoreInstance.sitemap = response.sitemap
  }

  @GenericStoreAsyncMethod()
  async handleSignUp(values: BaseInfoFormModel) {
    const response = await signUp(values)

    //TODO Fix hubspot
    //await hubSpotStoreInstance.handleUpdateOrCreateContact(values)
    
    userStoreInstance.userData = { ...response.userData, organisation: this.getJwtField('org') }
    userStoreInstance.sitemap = response.sitemap
  }

  @GenericStoreAsyncMethod()
  async handleSignUpByGoogle(accessToken: string) {
    return signUpByGoogle(accessToken)
  }

  @GenericStoreAsyncMethod({ withAllLoading: false, throwOnError: false })
  async handleUpdateAccessToken() {
    this.accessToken = await updateAccessToken()
  }

  @GenericStoreAsyncMethod({ withAllLoading: false, throwOnError: false })
  async handleResendLink() {
    // this.verificationLoader = true
    const response = await axiosInstance.request({
      url: '/app/resendLink',
      method: 'post',
    })
    // this.verificationLoader = false

    return response.data
  }

  @GenericStoreAsyncMethod()
  async handleResetPassword({ password, confirmPassword }: ResetPasswordFormModel, token: string) {
    const { translation } = localizationStoreInstance
    const { resetPassword: resetPasswordPage } = translation.pages

    if (password !== confirmPassword) {
      throw new Error(localTranslate(resetPasswordPage.errors.differentPasswords))
    } else if (!token) {
      throw new Error(localTranslate(resetPasswordPage.errors.invalidLink))
    }

    await resetPassword(password, confirmPassword, token)
  }

  async handleSignOut() {
    await signOut()
    this.reset()
  }

  hasRole(role: OrganisationRole): boolean {
    const roles = this.getJwtField('roles')

    if (roles && parseInt(roles)) {
      const currentRole = parseInt(roles) as OrganisationRole

      return (currentRole & role) === role
    }

    return false
  }

  getJwtField(prop: string): string | undefined {
    if (!this.accessToken) {
      return undefined
    }

    const base64Url = this.accessToken.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(window.atob(base64).split('')
      .map(function(c) {
        const cc = `00${c.charCodeAt(0).toString(16)}`

        return `%${cc.slice(-2)}`
      })
      .join(''))

    const tokenObj = JSON.parse(jsonPayload)

    return tokenObj[prop]
  }

  @GenericStoreAsyncMethod()
  async handleForgotPassword({ email }: ForgotPasswordFormModel) {
    await forgotPassword(email)
  }

  @GenericStoreAsyncMethod()
  async handleVerifyAccount(email: string, hashToken: string) {
    await verifyAccount(email, hashToken)
  }

  @GenericStoreAsyncMethod()
  async handleSsoVerify(samlRequestId: string, provider?: string): Promise<SamlResponse> {
    return await ssoVerify(samlRequestId, provider)
  }
}

export const {
  storeInstance: authStore,
  useStore: useAuthStore,
  StoreProvider: AuthStoreProvider,
} = createStore(new AuthStore())
