import { AppTableCell } from '../app-analog/atom/AppTableCell'
import { TableHeadSubColumns } from '../molecule/TableHeadSubColumns'
import { AppTableRow } from '../app-analog/atom/AppTableRow'
import { TranslatableText } from '../../../localization/component/atom/TranslatableText'
import { ExtendedColumn } from '../../model/Table'
import { If } from '../../../../component/common/original/atom/If'
import { Theme } from '@mui/material'
import { AppTableHead, AppTableHeadProps } from '../app-analog/atom/AppTableHead'

interface Props extends AppTableHeadProps {
  columns: ExtendedColumn[]
}

export function CustomTableHead(props: Props) {
  const { columns, children, ...rest } = props

  return (
    <AppTableHead {...rest}>
      <AppTableRow>
        {columns.map((column: ExtendedColumn) => (
          <AppTableCell
            key={column.key}
            sx={styles.cell}
            colSpan={column?.subColumns?.length}
          >
            <TranslatableText textValue={column?.value} />

            <If condition={column?.subColumns}>
              <hr />
              <TableHeadSubColumns subColumns={column.subColumns!} />
            </If>
          </AppTableCell>
        ))}
      </AppTableRow>
    </AppTableHead>
  )
}

const styles = {
  cell: ({ handleCssUnit, palette }: Theme) => ({
    borderBottom: `${handleCssUnit(1)} solid ${palette.grey[7]}`,
  }),
}
