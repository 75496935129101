import { FormControl, InputLabel, TextField, Theme } from '@mui/material'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AppCheckbox } from './AppCheckbox'
import { AppFormControlWithLabel } from './AppFormControlWithLabel'

export type AppDatePickerProps = {
  label: string
  noValueLabel?: string
  id: string
  defaultValue?: Date
  onChange?: (value: Date | undefined) => void
  disabled?: boolean
}

export const AppDatePicker = observer((props: AppDatePickerProps) => {
  const [value, setValue] = useState<Date | undefined>(props.defaultValue || new Date())
  const [noValue, setNoValue] = useState<boolean>(props.defaultValue === undefined && props.noValueLabel !== undefined)

  const handleChange = (newValue: any) => {
    const newDate = newValue.toDate() as Date

    if (newDate) {
      setValue(newDate)
    } else {
      setValue(undefined)
    }

    if (props.onChange) {
      props.onChange(newDate || undefined)
    }
  }

  const toggleNoValue = (_: boolean) => {
    setNoValue(!noValue)
    if (props.onChange) {
      props.onChange(noValue ? value : undefined)
    }
  }

  return (
    <FormControl sx={styles.controlContainer}>
      <InputLabel sx={styles.label} htmlFor={`date-input-${props.id}`}>{props.label}</InputLabel>
      {props.noValueLabel && <AppFormControlWithLabel control={<AppCheckbox checked={noValue} onClick={toggleNoValue} />} label={props.noValueLabel} />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          disabled={noValue || props.disabled}
          inputFormat="YYYY-MM-DD"
          value={value}
          onChange={handleChange}
          renderInput={params => <TextField sx={styles.field} id={`date-input-${props.id}`} {...params} />} />
      </LocalizationProvider>
    </FormControl>
  )
})

const styles = {
  controlContainer: {
    marginBottom: '1.5em',
  },
  label: ({ palette }: Theme) => ({
    color: palette.common.black,
    position: 'static',
    transform: 'none',
    margin: '0 auto 1.142em 0',
    fontWeight: 600,
    fontSize: '0.875em',
    lineHeight: 1.28,
    fontStyle: 'normal',
    width: 'auto',
  }),
  field: (theme: Theme) => ({
    fontSize: '1em',
    border: `0.119em solid ${theme.palette.grey[0]}`,
    backgroundColor: theme.palette.grey[0],
    transition: theme.transitions.create([
      'border-color',
    ]),
    borderRadius: theme.shape.borderRadius,
    '& fieldset': {
      border: 'none',
      borderRadius: '0px',
    },
    input: {
      fontSize: '1em',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      borderWidth: '0.119em',
    },
  }),
}
