import { TranslatableBox, TranslatableBoxProps } from '../../../localization/component/atom/TranslatableBox'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import { handlePassedSx } from '../../../theme/model/Sx'
import { Theme } from '@mui/material'
import { NotOverridableComponentProps } from '../../../../model/Component'

export type AppLabelProps = NotOverridableComponentProps<TranslatableBoxProps & InputLabelProps>

export function AppInputLabel(props: AppLabelProps) {
  const { sx = [], ...rest } = props
  
  return (
    <TranslatableBox
      component={InputLabel}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = ({ handleCssUnit }: Theme) => ({
  fontWeight: 600,
  fontSize: handleCssUnit(14),
  lineHeight: 1.28,
  fontStyle: 'normal',
})
