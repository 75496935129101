import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { Word } from '../../../localization/component/atom/Word'

export const RecommendedCourses = observer(() => {
  const eLearningStore = useELearningStore()
  const { translation, locale } = useLocalizationStore()
  const texts = translation.pages.eLearning
  
  useEffect(() => {
    if (!eLearningStore.recommendedCourses) {
      eLearningStore.getRecommendedCourses(locale)
    }
  }, [])

  return (
    <AppBox sx={styles.container}>
      <AppBox sx={styles.titleContainer}>
        <Title2>{texts.recTitle()}</Title2>
        <Word sx={styles.pill}>{eLearningStore.recommendedCourses?.length || 0}</Word>
      </AppBox>
      <AppCarousel>
        {eLearningStore.recommendedCourses && eLearningStore.recommendedCourses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
        {eLearningStore.recommendedCourses && eLearningStore.recommendedCourses.length === 0 && <Caption textValue={texts.missingCourse} />}
      </AppCarousel>
    </AppBox>
  )
})

const styles = {
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  titleContainer: {
    alignItems: 'center',
  },
  pill: {
    marginLeft: '10px',
    borderRadius: '1rem',
    border: '1px solid',
    borderColor: 'text.primary',
    padding: '0px 12px',
    backgroundColor: 'background.default',
    color: 'text.primary',
  },
}
