import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../../../../../component/common/analog/molecule/AppBox'
import Table, { TableProps } from '@mui/material/Table'

export type AppTableProps = NotOverridableComponentProps<TableProps>

export function AppTable(props: AppTableProps) {
  const { stickyHeader = true, ...rest } = props

  return (
    <AppBox
      component={Table}
      display={'table'}
      stickyHeader={stickyHeader}
      {...rest}
    />
  )
}
