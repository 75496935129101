import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Palette } from 'src/feature/theme/model/Palette'
import { MediaDisplay } from '../../../../feature/company-page/component/MediaDisplay'
import { Box } from '@mui/material'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
}

export const CategoryItem = observer(({ item }: CategoryItemProp) => {
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const texts = categories[item.titleLocale as ObjectKey]

  return (
    <AppBox sx={styles.root} style={{ backgroundColor: texts.color() }} onClick={() => navigate(appRoutePath.learningCategory(item.titleLocale))}>
      <Box sx={styles.imageContainer}>
        <MediaDisplay url={texts.icon()} type='image/png' />
      </Box>
      <Title3 sx={styles.text} textValue={texts.title()} />
    </AppBox>
  )
})

const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    minHeight: '130px',
    minWidth: '260px',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    PointerEvent: 'none',
  },
  imageContainer: {
    display: 'flex',
    width: '90px',
    height: '90px',
  },
  text: {
    wordWrap: 'break-word',
    textAlign: 'center',
    color: Palette.WHITE,
  },
}
