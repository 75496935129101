import { observer } from 'mobx-react-lite'
import { Box, CircularProgress, CircularProgressProps, circularProgressClasses } from '@mui/material'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Palette } from 'src/feature/theme/model/Palette'

export type CircularProgressWithLabelProp = CircularProgressProps & {
  value: number
}

export const CircularProgressWithLabel = observer((props: CircularProgressWithLabelProp) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', width: '50px', height: '50px' }}>
      <CircularProgress variant="determinate" value={100} sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        color: Palette.BRIGHT_GREY_2,
      }} size={50} thickness={4} />
      <CircularProgress variant="determinate" sx={{
        color: Palette.HAN_BLUE,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }} size={50} thickness={4} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Caption
          variant="caption"
          component="div"
          color="text.dark"
          sx={{ fontWeight: 'bold', marginTop: '1px' }}
        >{`${Math.round(props.value)}%`}</Caption>
      </Box>
    </Box>
  )
})