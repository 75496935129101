import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../../../../../component/common/analog/molecule/AppBox'
import TableHead, { TableHeadProps } from '@mui/material/TableHead'

export type AppTableHeadProps = NotOverridableComponentProps<TableHeadProps>

export function AppTableHead(props: AppTableHeadProps) {
  return (
    <AppBox
      component={TableHead}
      display={'table-header-group'}
      {...props}
    />
  )
}
