import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
//import { IconComponent, IconNames } from './CategoryIcon'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { ELearningCoursePlanItem } from '../../model/ELearningCoursePlanItem'
import { Image } from 'src/feature/image/component/Image'
import { PaletteMix } from 'src/feature/theme/model/Palette'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import UpdateIcon from '@mui/icons-material/Update'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Rating, Tooltip } from '@mui/material'
import { CourseProgressRow } from './CourseProgressRow'
import { useState } from 'react'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useELearningStore } from '../../store/e-learning.store'

export type CoursePlanItemProp = {
  item: ELearningCoursePlanItem
  index: number
}

export const CoursePlanItem = observer(({ item, index }: CoursePlanItemProp) => {
  const { translation } = useLocalizationStore()
  const eLearningStore = useELearningStore()
  const navigate = useNavigate()
  const color = PaletteMix[index % PaletteMix.length]
  const texts = translation.pages.eLearning
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const removeItem = async () => {
    await eLearningStore.removeCoursePlanItem(item.id)
    setShowConfirmDelete(false)
  }

  return (
    <AppBox sx={styles.root} >
      <Image src={item.imageUrl} alt={item.title} outerProps={{ sx: styles.image }} />
      <AppBox sx={styles.inner} style={{ backgroundColor: color }}>
        <Title3 sx={styles.text} textValue={item.title} noWrap />
        <AppBox>
          <Rating defaultValue={item.progress?.percentage || 0} precision={0.5} disabled={(item.progress?.percentage || 0) < 50} />
        </AppBox>
        <CourseProgressRow progress={item.progress} />
        <AppBox sx={styles.row}>
          {item.isUserSelected && <AppIconButton sx={styles.iconButton} onClick={() => setShowConfirmDelete(true)}><DeleteIcon fontSize='medium' /></AppIconButton>}
          {item.mandatory && <Tooltip title={(item.dealine ? new Date(item.dealine) < new Date() ? texts.courseOverdue(new Date(item.dealine).toLocaleDateString()) : texts.courseMandatoryWithDeadline(new Date(item.dealine).toLocaleDateString()) : texts.courseMandatory())}><UpdateIcon fontSize='medium' color='error' /></Tooltip>}
          <AppIconButton sx={styles.iconButton} onClick={() => navigate(appRoutePath.learningCourse(item.courseId))}><ArrowForwardIosIcon fontSize='medium' /></AppIconButton>
        </AppBox>
      </AppBox>
      <ModalWindow modalProps={{ open: showConfirmDelete, onClose: () => setShowConfirmDelete(false) }}
        titleProps={{
          variant: 'h4',
          textValue: translation.buttons.confirm,
        }}>
        <AppBox sx={styles.dialogContent}>
          <Paragraph textValue={texts.confirmDelete(item.title)} m={5} />
          <AppButton
            textValue={translation.buttons.confirm}
            variant={'contained'} m={2}
            onClick={removeItem}
          />
        </AppBox>
      </ModalWindow>
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '10px 0 0 10px',
    minHeight: '175px',
    minWidth: '420px',
    overflow: 'hidden',
  },
  inner: {
    padding: '15px 20px',
    flexDirection: 'column',
    borderRadius: '0 10px 10px 0',
    borderColor: 'background.default',
    gap: 3,
    maxWidth: '220px',
    minWidth: '220px',
  },
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    whiteSpace: 'nowrap',
    color: 'text.dark',
  },
  image: {
    maxHeight: '175px',
    minHeight: '175px',
    maxWidth: '200px',
    minWidth: '200px',
  },
  iconButton: {
    borderWidth: 0,
    color: 'text.dark',
  },
  dialogContent: {
    flexDirection: 'column',
  },
}
