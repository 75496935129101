import { Translatable } from '../../../../feature/localization/model/Text'
import { BaseGoBackButton } from '../atom/BaseGoBackButton'
import { useLocalizationStore } from '../../../../feature/localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import ArrowLeftIcon from 'src/feature/image/asset/icon/arrow-left.svg'
import { IconButtonWithText } from './IconButtonWithText'

interface Props {
  textValue?: Translatable
}

export const GoBackButtonWithArrow = observer((props: Props) => {
  const localization = useLocalizationStore()

  const { textValue = localization.translation.buttons.goBack } = props

  return (
    <IconButtonWithText
      rootProps={{
        component: BaseGoBackButton,
        sx: styles.root,
      }}
      imageProps={{
        src: ArrowLeftIcon,
        alt: localization.translation.imgAlt.leftArrow,
      }}
      textProps={{
        textValue,
      }}
    />
  )
})

const styles = {
  root: {
    marginBottom: {
      xs: '0.5em',
      md: '1em',
    },
  },
}
