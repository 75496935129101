import { Theme } from '@mui/material'
import { ChildrenProps } from '../../../../model/React'
import { AppTable, AppTableProps } from '../app-analog/atom/AppTable'
import { AppTableContainer, AppTableContainerProps } from '../app-analog/atom/AppTableContainer'
import { handlePassedSx } from '../../../theme/model/Sx'

interface Props extends ChildrenProps {
  tableProps?: AppTableProps
  containerProps?: AppTableContainerProps
}

export function CustomTable(props: Props) {
  const { containerProps: { sx: tablePropsSx = [], ...restContainerProps } = {}, tableProps = {} } = props

  return (
    <AppTableContainer
      sx={[
        styles.root,
        ...handlePassedSx(tablePropsSx),
      ]}
      {...restContainerProps}
    >
      <AppTable
        {...tableProps}
      >
        {props.children}
      </AppTable>
    </AppTableContainer>
  )
}

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    maxHeight: handleCssUnit(800),
    fontSize: handleCssUnit(16),
  }),
}
