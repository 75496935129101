import { observer } from 'mobx-react-lite'
import { Card, CardContent, CardMedia, Rating } from '@mui/material'
import { useELearningStore } from '../../store/e-learning.store'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { CourseListItem } from '../../model/ELearningCourseListItem'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Palette } from 'src/feature/theme/model/Palette'
import { useEffect, useState } from 'react'

export type CourseListPresentationItemProps = {
  item: CourseListItem
  courseSelectedFlag?: boolean
  courseSelectedId?: (courseId: string) => void
}

export const CourseListPresentationItem = observer(({ item, courseSelectedFlag = false, courseSelectedId }: CourseListPresentationItemProps) => {
  const eLearningStore = useELearningStore()
  const navigate = useNavigate()
  const [isFavorite, setIsFavorite] = useState(item.isFavorite)

  const toggleFavorite = async (e: React.FormEvent<HTMLFormElement>, id: string) => {
    e.stopPropagation()
    console.log('x')
    setIsFavorite(prev => !prev)
    await eLearningStore.toggleFavoriteCourse(id)
    await eLearningStore.updatePersonalCoursePlan()
  }

  useEffect(() => {
    eLearningStore.getCourseInfo(item.id)
  }, [])

  return (<Card sx={styles.card} key={item.id} onClick={() => courseSelectedId ? courseSelectedFlag && courseSelectedId(item.id) : navigate(appRoutePath.learningCourse(item.id))}>
    <CardMedia
      component="img"
      alt="course icon/image"
      height="230"
      width="230"
      image={item.imageUrl}
    />
    <CardContent sx={styles.contentContainer}>
      <AppBox>
        <Rating defaultValue={0} precision={0.5} readOnly />
      </AppBox>
      <AppBox sx={styles.actions}>
        <Title3 textValue={item.title} gutterBottom />
        <AppIconButton
          onClick={(e: React.FormEvent<HTMLFormElement>) => toggleFavorite(e, item.id)}
          sx={styles.iconButton}
        >
          {isFavorite ? <FavoriteIcon fontSize='medium' /> : <FavoriteBorderIcon fontSize='medium' />}
        </AppIconButton>
      </AppBox>
    </CardContent>
  </Card>)
})

const styles = {
  card: {
    maxWidth: 260,
    minWidth: 260,
    cursor: 'pointer',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  textBox: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '75px',
    maxHeight: '95px',
  },
  iconButton: {
    borderWidth: 0,
    padding: 0,
    marginLeft: '5px',
    color: Palette.HAN_BLUE,
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '5px',
    gap: '5px',
  },
}
