import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { ELearningCoursePlanItem } from '../../model/ELearningCoursePlanItem'
import { CoursePlanItem } from './CoursePlanItem'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { Button } from '@mui/material'

export const PersonalCoursePlan = observer(() => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const texts = translation.pages.eLearning
  
  useEffect(() => {
    eLearningStore.updatePersonalCoursePlan()
  }, [])

  const transform = (item: ELearningCoursePlanItem, index: number) => <CoursePlanItem item={item} index={index} key={item.id} />
  const sort = (a: ELearningCoursePlanItem, b: ELearningCoursePlanItem) => {
    if (a.dealine && !b.dealine) {
      return -1
    }

    if (b.dealine && !a.dealine) {
      return 1
    }

    if (a.dealine && b.dealine) {
      return new Date(a.dealine).getTime() - new Date(b.dealine).getTime()
    }

    if (a.progress && !b.progress) {
      return -1
    }

    if (b.progress && !a.progress) {
      return 1
    }

    if (a.progress && b.progress) {
      return b.progress.percentage - a.progress.percentage
    }

    return new Date(a.created).getTime() - new Date(b.created).getTime()
  }

  const items = eLearningStore.personalCourcePlan ? [...eLearningStore.personalCourcePlan.filter(p => !p.progress && !p.isUserSelected).sort(sort), ...eLearningStore.personalCourcePlan.filter(p => p.progress && !p.progress.completed)] : []

  return (
    <AppBox sx={styles.container}>
      <AppBox sx={styles.titleContainer}>
        <Title2 textValue={texts.coursePlanTitle()} />
        <Button variant="outlined" sx={{ height: '2rem', width: '9rem' }} onClick={() => navigate(appRoutePath.coursePlan)}>
          {texts.exploreAllCourses()}
        </Button>
      </AppBox>
      <AppCarousel>
        {eLearningStore.personalCourcePlan && items.map(transform)}
        {eLearningStore.personalCourcePlan && items.length === 0 && <Caption textValue={texts.missingCourse} />}
      </AppCarousel>
    </AppBox>
  )
})

const styles = {
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '2rem',
  },
}
