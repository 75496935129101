import { axiosInstance } from 'src/util/axios/instance.axios'
import { CoursesAssignedOverview, CoursesOverview, UserCourseSummary, UsersLevel } from '../model/company-dashboard'
import { OrganisationLevel } from 'src/feature/b2b/model/OrganisationLevel'

export async function getCoursesOverview(): Promise<CoursesOverview> {
  const { data } = await axiosInstance.request<CoursesOverview>({
    url: `/company-dashboard/totalcourse-overview`,
  })

  return data
}

export async function getCoursesAssignedOverview(level: string): Promise<CoursesAssignedOverview> {
  const { data } = await axiosInstance.request<CoursesAssignedOverview>({
    url: `/company-dashboard/assignedcourse-overview/${level}`,
  })

  return data
}

export const getOrganisationLevels = async (): Promise<OrganisationLevel[]> => {
  const { data } = await axiosInstance.request<OrganisationLevel[]>({
    url: `/b2b/admin/organisation-levels`,
  })

  return data
}

export async function getUsers(level: string): Promise<UsersLevel[]> {
  const { data } = await axiosInstance.request<UsersLevel[]>({
    url: `/company-dashboard/users-level/${level}`,
  })

  return data
}

export async function getUserCourseSummary(userId: string): Promise<UserCourseSummary[]> {
  const { data } = await axiosInstance.request<UserCourseSummary[]>({
    url: `/company-dashboard/users-course-plan/${userId}`,
  })

  return data
}
