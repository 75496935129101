import { FC, useCallback } from 'react'
import { SnackbarKey, useSnackbar } from 'notistack'
import { AppButton } from '../../../../component/common/analog/molecule/button/AppButton'
import { useLocalizationStore } from '../../../localization/store/localization.store'

interface IProps {
  notificationKey?: SnackbarKey;
}

// eslint-disable-next-line react/function-component-definition
const NotifierCloseButton: FC<IProps> = ({ notificationKey }): JSX.Element => {
  const { closeSnackbar } = useSnackbar()
  const { translation } = useLocalizationStore()
  const _close = useCallback(() => {
    closeSnackbar(notificationKey)
  }, [notificationKey])

  return (
    <AppButton
      sx={{
        fontSize: 14,
        height: 18,
        backgroundColor: '#fefefe',
      }}
      textValue={translation.notification.closeButton}
      onClick={_close}
    />
  )
}

export { NotifierCloseButton }
