import { Theme } from '@mui/material'

import { AppTableCell, AppTableCellProps } from './AppTableCell'
import { handlePassedSx } from '../../../../theme/model/Sx'
import { NotOverridableComponentProps } from '../../../../../model/Component'

export type AppTableRowCellProps = NotOverridableComponentProps<AppTableCellProps>

export function AppTableRowCell(props: AppTableRowCellProps) {
  const { sx = [], ...rest } = props

  return (
    <AppTableCell
      scope="row"
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = ({ handleCssUnit }: Theme) => ({
  fontSize: handleCssUnit(16),
})
