export enum Palette {
  WASH_ME = '#FAFCFE',
  CHEFS_HAT = '#F2F5F7',
  BLACK_OUT = '#1F242C',
  BLACK_OUT_ALPHA_40 = '#1F242C66',
  BLACK_OUT_ALPHA_50 = '#1F242C80',
  GOSHAWK_GREY = '#2A2F37',
  GOSHAWK_GREY_ALPHA_40 = '#2A2F3766',
  GOSHAWK_GREY_ALPHA_50 = '#2A2F3780',
  GOSHAWK_GREY_ALPHA_60 = '#2A2F3799',
  GOSHAWK_GREY_ALPHA_70 = '#2A2F37B3',
  BRIGHT_GREY_2 = '#DFE3E8',
  BRIGHT_GREY = '#ECEDF0',
  VIOLET_HEAVEN_ALPHA = '#d5ddff',
  VIOLET_HEAVEN = '#C8B9F9',
  HAN_PURPLE = '#36BBAF',
  HAN_PURPLE_DARK = '#4012b8',
  HAN_BLUE = '#36BBAF',
  HAN_BLUE_LIGHT = '#75E1D7',
  DANGER = '#F34C4C',
  WHITE = '#ffffff',
  MIST_WHISPER = '#EAEAEA',
  MIDNIGHT_PINE= '#2F4345',
  SLATE_TEAL = '#6A8183',
}

export const PaletteMix = [
  '#e8f0ff', '#eee8ff', '#eeffea', '#f7f3f6', '#f8ffe2',
]